<template>
  <div id="account-selector">
    <div id="account-selector-dropdown">
      <v-autocomplete
        ref="autocomplete"
        v-model="selectedAccountId"
        outlined
        :label="label"
        item-text="name"
        item-value="original_id"
        item-color="primary"
        :clearable="clearable"
        :hide-details="hideDetails"
        :search-input.sync="searchAccount"
        :loading="accountsLoading"
        :items="accountsList"
        :return-object="returnObject"
        no-data-text="No accounts available"
        @change="emitOnChange"
        :rules="rules"
      >
        <template #append-item>
          <v-responsive class="overflow-y-auto" max-height="400">
            <span v-intersect="loadNextPage" />
          </v-responsive>
        </template>
      </v-autocomplete>

      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@/utils";
import { userAccounts } from "@/services/users";
import { DEBOUNCE_MILLIS, DEFAULT_PER_PAGE_RECORDS } from "@/constants/app";

/**
 * AccountSelector
 * @shared component
 * @author {Jatin Kamboj}
 * @description Accounts selector
 */
export default {
  name: "AccountSelector",
  /**
  |--------------------------------------------------
  | Custom events emitted by component
  |--------------------------------------------------
  */
  emits: ["changed"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    rules: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: false },
    label: { type: String, default: "Account Name" },
    returnObject: { type: Boolean, default: false },
    hideDetails: { type: [Boolean, String], default: false },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      loading: false,
      accountsLoading: false,
      accountsMeta: {
        page: 1,
        per_page: DEFAULT_PER_PAGE_RECORDS,
        name_cont: "",
      },
      userAccountsList: [],
      searchAccount: "",
      debounceTimer: null,
      selectedAccountId: "",
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    searchAccount(val) {
      if (!val) return;

      if (this.findAccount(val) == -1) {
        this.debounceAccountSearch();
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed Properties
  |--------------------------------------------------
  */
  computed: {
    accountsList() {
      if (!isEmpty(this.userAccountsList)) return this.userAccountsList;
      else return [];
    },
    /**
     * Do user have more accounts ?
     */
    hasMoreAccounts() {
      if (!this.accountsMeta?.total) return true;

      return (
        this.accountsMeta?.page * this.accountsMeta?.per_page <
        this.accountsMeta?.total
      );
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * @emits changed
     */
    emitOnChange(value) {
      this.$emit("changed", value);
    },
    /**
     * Debounce search accounts
     */
    debounceAccountSearch() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        const params = {
          name_cont: this.searchAccount,
          page: 1,
          per_page: DEFAULT_PER_PAGE_RECORDS,
        };

        if (this.defaultAccountDetails?.name !== this.searchAccount) {
          this.accountsMeta.name_cont = this.searchAccount;
        }
        this.fetchAccounts(params);
        this.debounceTimer = null;
      }, DEBOUNCE_MILLIS);
    },
    /**
     * Merges accounts
     */
    mergeAccounts(data = {}) {
      return [...this.userAccountsList, ...(data?.data ?? [])];
    },
    /**
     * fetchAccounts
     * @description Fetches user accounts
     */
    async fetchAccounts(params = this.accountsMeta) {
      try {
        params = { ...params };
        if (params.total) delete params.total;

        this.accountsLoading = true;
        const { data } = await userAccounts(params);

        if (!this.searchAccount && this.hasMoreAccounts) {
          this.accountsMeta = { ...data.meta };
        }

        this.userAccountsList = this.mergeAccounts(data);
      } finally {
        this.accountsLoading = false;
      }
    },
    findAccount(account) {
      const finder = ({ name }) => name.toLowerCase() == account.toLowerCase();
      return this.userAccountsList.findIndex(finder);
    },
    /**
     * Loads next page accounts records
     */
    loadNextPage() {
      if (this.accountsLoading) return;
      if (this.hasMoreAccounts && this.accountsMeta?.total > 0) {
        this.accountsMeta.page++;
        this.fetchAccounts();
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted Lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.fetchAccounts();
  },
};
</script>
